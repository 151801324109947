html, body {
  height: 100%;
  margin: 0;
}

/* Footer and Header css */
.NCBox {
  background-color: #000;
  align-items: center;
  justify-content: right;
  display: flex;
  flex-direction: row;
}

.NCLogo {
  margin-right: auto;
  margin-top: 10px;
}

.NCLogoNavigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.NCNavigation {
  color: #fff;
  display: flex;
  flex-direction: row;
  width: auto;
  margin-left: auto;
}

.NCNavItem {
  width: auto;
  margin-left: 7px;
  margin-right: 7px;
  font-size: 1.125rem;
}



/* Background */
.LandingPage {
  background-color: #011326;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.LPButtons {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
  align-self: center;
}

.LPButton {
  width: 250px;
  margin: 3px;
}

/* LOGIN PAGE */
.mg-top {
	margin-top: 80px;
}
div.text-center {
	box-sizing: border-box;
	color: #212529;
	font-family: Nunito, -apple-system, "system-ui", "Segoe UI", Roboto,
		"Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	letter-spacing: 1px;
	line-height: 24px;
	text-align: center;
}
.hr-text {
	background: linear-gradient(to right, transparent, #000000, transparent);
	line-height: 24px;
}

.spinner-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 5000;
	display: flex;
	justify-content: center;
	align-items: center;
}

.spinner {
	width: 100px;
	height: 100px;
	border: 10px solid;
	border-color: #000 transparent #555 transparent;
	border-radius: 50%;
	animation: spin 1.2s linear infinite;
}

/* Main page */
.ver1AppBox {
  width: auto;
  height: max-content;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  background-color: #0b1d4d;

}

.ver1AppBox {
  text-align: center;
}



.ContainerBox {
  background-image: url("images/background.jpg") ;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 100%;
  max-width: 100%;
}


.RangeViewerStackSizes {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.RangeViewerComponentPreflopAction {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.RangeViewerComponentPreflopActionAction, .RangeViewerComponentPreflopActionPlayer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 1px;
  margin-right: 1px;
}

.RangeViewerComponentRanges {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}


.rangeManual {
  width: auto;
  height: 50%;
}

.titleRange {
  color: white;
}

.RangeViewerComponentRangesRange {
  width: 300px;
  height: 300px;
  margin-right: 1rem;
  max-width: 100%;
  height: auto;

}

/* FOOTER */
.Footer {
  position: relative;
  bottom: 0;
  padding-top: 1rem;
}

/* Styles for phones */
@media only screen and (max-width: 1000px) {
  Footer {
    display: none;
  }
}



/* MainPageNew */

.containerBoxNew {
  background-image: url("images/background.jpg") ;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;
  display: flex;
  justify-content: left;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow: auto;
  max-height: 100%;
  max-width: 100%;
}

.allButtons {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-left: 0;
  margin-top: 20px;
}

.btnlist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1px;
  width: 60px;
}


.clearAnd3Bet {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
  margin-left: 10px;
  width: 150px;
  padding-bottom: 23px;
  z-index: 2;
}

.mg-b-40 {
  margin-bottom: 58px;
}

.faqbtn{
  margin-top: 30px;
  margin-left: 0px;
  z-index: 2;
}

.charts{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: left;
  margin-top: 20px;
  margin-left: 20px;
}.text {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 4px;
  margin-top: 10px;
  text-align: center;
}

.message{
  color: red;
  font-size: 1.5rem;
  margin-bottom: 4px;
  margin-top: 20px;
  margin-left: 70px;
  margin-right: 60px;
  text-align: center;

}

.betchart{
  flex-direction: row;
  margin-left: 10px;
}

.singleChart{
  width: 500px;
  height: 350px;
  /* object-fit: contain; Optional: Dopasowanie obrazu wewnątrz kontenera */
  image-rendering: -webkit-optimize-contrast; /*Optional: Poprawa ostrości obrazu w Chrome i nie tylko*/
}



@media screen and (max-width: 1300px) { /*Optional: Zmiana wielkości obrazu w zależności od szerokości ekranu*/
  .singleChart {
    margin-top: 1rem;
    margin-left: 1rem;
    width: 490px;
    height: 220px;
    /*object-fit: contain;  Optional: Dopasowanie obrazu wewnątrz kontenera */
    image-rendering: -webkit-optimize-contrast; /*Optional: Poprawa ostrości obrazu w Chrome i nie tylko*/
  }
}

button {
  border: 1px solid black;
  border-radius: 5px;
  font-family: "Arial", sans-serif;
}

.ContainerBox button:enabled {
  background-color: white;
  cursor: pointer;
}

button:disabled {
  background-color: darkgray;
}

.btnUnActiv {
  background-color: rgb(252, 252, 252);
}

.btnActiv {
  background-color: rgb(75, 196, 237) !important;
}

/* FAQ COMPONENT */

.ml-5 {
  margin-left: 5px;
}

.mb-2 {
  margin-bottom: 2px;
}
.mb-40 {
  margin-bottom: 40px;
}

.mt-5 {
  margin-top: 2px;
}

.m3 {
  margin: 3;
}

.colors-pictrue {
  width: 25px !important;
  height: 20px !important;
  object-fit: cover;
  vertical-align: top;
}
